import {
  UPDATE,
  UPDATE_LIST,
  UPDATE_BOUNDARIES
} from './constants';

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_LIST:
      return { ...state, geofenceList: action.geofenceList };
    case UPDATE:
      return { ...state, updateFlag: action.updateFlag };
    case UPDATE_BOUNDARIES:
        return { ...state, boundaries: action.boundaries };
    default:
      return state;
  }
};

export default reducer;
