import React, { memo, useEffect, useState } from 'react';
import { withApollo, compose } from 'react-apollo';
import { Route, Switch, withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import { get } from 'lodash';
import Routes from 'routes';
import * as Sentry from '@sentry/react';
import { getCurrentUser, getAssignedVehiclesCount, getSystemSettings } from 'requests/auth';
import { useAlert, useAuth } from 'hooks';
import {
  getErrorMessages,
  ignoredErrorsListSentry,
  getSentryDsn,
  isProduction
} from 'utils/global';
import Welcome from 'routes/Welcome';
import AuthCallback from 'routes/AuthCallback';
import Loader from 'components/Loader';
import { DarkModeProvider } from 'context/DarkModeContext/DarkModeContext';

// Initialize Sentry once when the app loads
if (isProduction()) {
  Sentry.init({
    dsn: getSentryDsn(),
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
      })
    ],
    replaysOnErrorSampleRate: 1.0,
    release: process.env.FRONTVERSION,
    ignoreErrors: ignoredErrorsListSentry,
    tracesSampleRate: 1.0,
  });
}

const App = ({ client, location }) => {
  const { openAlert: alert } = useAlert();
  const {
    user,
    setCurrentUser,
    setAssignedVehiclesCount,
    setVehiclesWithLocationCount,
    setSystemSettings,
    isLogged,
  } = useAuth();
  const [loadApp, setLoadApp] = useState(false);

  const initUser = async () => {
    const [user, assignedVehicles] = await Promise.all([
      client.query({ query: getCurrentUser }),
      client.query({ query: getAssignedVehiclesCount }),
    ]);
    setCurrentUser(user.data.user);
    setAssignedVehiclesCount(assignedVehicles.data.vehicles.pagination.totalEntries);
    setVehiclesWithLocationCount(assignedVehicles.data.vehicles.pagination.totalEntries);
  };

  const initSystemSettings = async () => {
    const { data: { systemSettings } } = await client.query({ query: getSystemSettings });
    const defaultCountry = systemSettings.find(item => item.key === 'countries.default').value;
    const onlyCountries = systemSettings.find(item => item.key === 'countries.all').value.split(',');
    setSystemSettings({ defaultCountry, onlyCountries });
  };

  // Update Sentry user context whenever the `user` changes
  useEffect(() => {
    if (isProduction() && user) {
      Sentry.setUser({ username: user?.account?.billingEmail });
    } else {
      Sentry.setUser(null); // Clear user context if no user is logged in
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      const token = get(
        parse(location.hash), 'access_token',
        window.localStorage.getItem('access_token')
      );
      try {
        await initSystemSettings();
        if (token || isLogged) await initUser(token);
      } catch (e) {
        getErrorMessages(e).forEach(err => alert(err, 'error'));
      } finally {
        setLoadApp(true);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (location.pathname === '/share_track') {
        // eslint-disable-next-line camelcase
        const { realtime_token, nickname = '', tracking_object_uuid, vehicle_id = '', expires_at } = parse(location.search);
        sessionStorage.setItem('share_track_token', realtime_token);
        sessionStorage.setItem('nickname', nickname);
        sessionStorage.setItem('vehicle_id', vehicle_id);
        sessionStorage.setItem('tracking_object_uuid', tracking_object_uuid);
        sessionStorage.setItem('expires_at', expires_at);
      }
    })();
  }, []);

  if (!loadApp) return <Loader />;

  return (
    <Switch>
      <Route exact path="/" component={Welcome} />
      <Route path="/login" component={AuthCallback} />
      <DarkModeProvider>
        <Routes />
      </DarkModeProvider>
    </Switch>
  );
};

export default compose(
  memo,
  withApollo,
  withRouter
)(App);
