import gql from 'graphql-tag';
import { Fragments } from 'requests/fragments';

export const getLastPointBatteryVoltage = gql`
query getLastPointBatteryVoltage(
  $filter: FilterByPoint
  $pagination: PaginationParams
  $sort: SortBy
) {
  lastPointBatteryVoltage(filter: $filter, pagination: $pagination, sortBy: $sort) {
    entities {
      data {
        batteryVoltage
      }
    }
  }
}
`;

export const getTripByIdAndVehicleId = gql`
  query getTrip($vehicleId: ID!, $tripId: ID!){
    vehicle(id: $vehicleId){
      id
      trip(id: $tripId){
        id
        points{
          entities{
            location{
              address{
                fullAddress
              }
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`;

export const getVehiclesFilterQuery = gql`
  query getVehiclesFilterQuery(
    $pagination: PaginationParams
    $filter: FilterByVehicle
    $search: VehicleSearch
    $sortBy: VehiclesSortBy
  ) {
    vehicles(
      pagination: $pagination
      filter: $filter
      search: $search
      sortBy: $sortBy
    ) {
      entities {
        id       
        make
        model       
        nickname       
        trackingObjectUuid
        trackingObjectType {
          name
          id
        }        
        currentDriver {
          id
          user {
            id
            firstName
            lastName
          }
        }      
      }
      pagination {
        ...Pagination
      }
    }
  }
  ${Fragments.PaginationFragment}
`;

export const getVehiclesQuery = gql`
  query getVehicles(
    $pagination: PaginationParams
    $filter: FilterByVehicle
    $search: VehicleSearch
    $sortBy: VehiclesSortBy
  ) {
    vehicles(
      pagination: $pagination
      filter: $filter
      search: $search
      sortBy: $sortBy
    ) {
      entities {
        id
        vin
        make
        model
        year
        assetTemperature
        assetTemperatureTimestamp
        serialNumber
        nickname
        licensePlate
        photoUrl
        status
        tireIssue
        newVin    
        deviceOdometer
        calculatedOdometer
        deviceEngineHours
        calculatedEngineHours
        deviceOdometerOffsetComment
        deviceEngineHoursOffsetComment
        idleTimeCalcLogic
        maintenanceThreshold
        idleTime   
        postedSpeedLimitBuffer
        thresholdSpeedLimit
        batteryVoltage
        engineTemperature
        idleThreshold
        engineTemperatureThreshold
        batteryVoltageThreshold
        smartcarEnabled
        smartcarAccessToken
        smartcarRefreshToken
        smartcarUser
        smartcarPassword
        smartcarVehicleId
        lastTrip (status: [IMPOSSIBLE, NORMAL, SHORT, UNUSUAL]) {
         ...ExtendedTrip
         idleTimeCalcLogic
        }
        
        tires {
          airLeakageRate
          axle
          faultCode
          id
          psi
          temperature
          tire
        }
        odometer {
          value
          calcLogic
        }
        fuelUsed
        engineHours {
          value
          calcLogic
        }
        deviceOdometerOffset
        deviceEngineHoursOffset
        dtcIssueCount(active: true)
        batteryIssue
        engineIssue
        dtcIssue
        tireIssue
        drivingStatus
        drivingStatusTimestamp
        fuelType
        tagsCount
        engineType
        fuelConsumption
        fuelLevel
        fuelLevelThreshold
        trackingObjectUuid
        trackingObjectType {
          name
          id
        }
        issuesLogCount {
          battery: batteryIssue
          dtc: dtcIssue
          engine: engineIssue
          tires: tireIssue
        }
        location: drivingStatusLocation {
          speed
          latitude
          longitude
          address {
            fullAddress
            city
            country
            countryCode
            fullAddress
            houseNumber
            latitude
            longitude
            postalCode
            state
            stateCode
            street
          }
        }
        currentScore
        drivingStatusPlace {
          id
          name
          address
        }
        currentDriver {
          id
          user {
            id
            firstName
            lastName
          }
        }
        devicesInfo {
          id
          mobileId
          type
          iccid
          activity {
            lastPing
            status
          }
          deviceModel {
            id
            make
            model
            type
            sensors {
              temperature
            }
          }
        }
        lastRealtimeTrip {
          id
          startTime
        }
        lastTrip (status: [IMPOSSIBLE, NORMAL, SHORT, UNUSUAL]) {
          id
          driver {
            id
            user {
              id
              firstName
              lastName
            }
          }
          endTime
          startTime
          stopPlace {
            id
            name
            address
          }
          driver {
            id
            user {
              firstName
              lastName
              id
            }
          }
        }
        firstTrip {
          id
          driver {
            id
            user {
              id
              firstName
              lastName
            }
          }
          endTime
          startTime
          stopPlace {
            id
            name
            address
          }
          driver {
            id
            user {
              firstName
              lastName
              id
            }
          }
        }
      }

      pagination {
        ...Pagination
      }
    }
  }
  ${Fragments.PaginationFragment}
  ${Fragments.ExtendedTripFragments}
`;

export const getVehiclesQueryMain = gql`
  query getVehiclesQueryMain(
    $pagination: PaginationParams
    $filter: FilterByVehicle
    $search: VehicleSearch
    $sortBy: VehiclesSortBy
  ) {
    vehicles(
      pagination: $pagination
      filter: $filter
      search: $search
      sortBy: $sortBy
    ) {
      entities {
      id
      make
      model
      vin
      year
      nickname
      drivingStatus
      drivingStatusTimestamp
      trackingObjectUuid
      lastTrip {
        startTime
        endTime
        driver {
          id
        }
        stopPlace {
          name
        }
      }
      lastRealtimeTrip {
        id
        startTime
      }
      location: drivingStatusLocation {
        speed
        latitude
        longitude
        address {
          fullAddress
          city
          country
        }
      }
      odometer {
        calcLogic
        value
      }
      tires {
        airLeakageRate
        axle
        psi
        temperature
        tire
      }
      currentDriver {
        id
        user {
          id
          firstName
          lastName
        }
      }
      devicesInfo {
        iccid
        deviceModel {
          make
          model
        }
      }
    }

      pagination {
        ...Pagination
      }
    }
  }
  ${Fragments.PaginationFragment}
`;

export const getVehiclesMapQueryMain = gql`
  query getVehiclesMapQuery(
    $pagination: PaginationParams
    $filter: FilterByVehicle
    $search: VehicleSearch
    $sortBy: VehiclesSortBy
  ) {
    vehicles(
      pagination: $pagination
      filter: $filter
      search: $search
      sortBy: $sortBy
    ) {
      entities {
        id
        nickname
        fuelLevelThreshold
        drivingStatus
        fuelLevel
        lastTrip{
          endTime
          startTime
            driver{
              id
            }
          stopPlace
          {
            name
          }
          geofenceAlertCounter
        }   
        lastRealtimeTrip {
          id
          startTime
        }   
        drivingStatusPlace {
          name
          address
        }
        drivingStatus
        drivingStatusTimestamp
        trackingObjectUuid
        trackingObjectType {
          name
          id
        }
        issuesLogCount {
          battery: batteryIssue
          dtc: dtcIssue
          engine: engineIssue
          tires: tireIssue
        }
        location: drivingStatusLocation {
          speed
          latitude
          longitude
        }
  
        }

      pagination {
        ...Pagination
      }
    }
  }
  ${Fragments.PaginationFragment}
`;

export const getVehiclesMapQuery = gql`
  query getVehiclesMapQuery(
    $pagination: PaginationParams
    $filter: FilterByVehicle
    $search: VehicleSearch
    $sortBy: VehiclesSortBy
  ) {
    vehicles(
      pagination: $pagination
      filter: $filter
      search: $search
      sortBy: $sortBy
    ) {
      entities {
        id
        make
        model
        vin
        year
        assetTemperature
        serialNumber
        dtcIssue
        nickname
        status
        batteryVoltage
        engineTemperature   
        currentScore  
        drivingStatusTimestamp
        bodyType
        smartcarEnabled
        smartcarAccessToken
        smartcarRefreshToken
        smartcarUser
        smartcarPassword
        smartcarVehicleId
        fuelLevel
        lastTrip{
          endTime
          startTime
            driver{
              id
            }
          stopPlace
          {
            name
          }
          geofenceAlertCounter
        }   
        lastRealtimeTrip {
          id
          startTime
        }   
        drivingStatusPlace {
          id
          name
          address
        }
        tires {
          airLeakageRate
          axle
          faultCode
          id
          psi
          temperature
          tire
        }
        odometer {
          value
          calcLogic
        }
        engineHours {
          value
          calcLogic
        }
        drivingStatus
        drivingStatusTimestamp
        trackingObjectUuid
        trackingObjectType {
          name
          id
        }
        issuesLogCount {
          battery: batteryIssue
          dtc: dtcIssue
          engine: engineIssue
          tires: tireIssue
        }
        location: drivingStatusLocation {
          speed
          latitude
          longitude
          address {
            fullAddress
            city
            country
            countryCode
            fullAddress
            houseNumber
            latitude
            longitude
            postalCode
            state
            stateCode
            street
          }
        }
  
        devicesInfo {
          id
          mobileId
          type
          iccid
          activity {
            lastPing
            status
          }
          deviceModel {
            id
            make
            model
            type
            sensors {
              temperature
            }
          }         
        }
        fuelType
        engineType
        tagsCount
        currentDriver {
          id
          bleDriverId
          user {
            id
            firstName
            lastName
          }
        }
      }

      pagination {
        ...Pagination
      }
    }
  }
  ${Fragments.PaginationFragment}
`;

export const getVehiclesBasicInfo = gql`
  query getVehicles($pagination: PaginationParams, $filter: FilterByVehicle, $search: VehicleSearch) {
    vehicles(pagination: $pagination, filter: $filter, search: $search) {
      entities {
        id
        vin
        make
        model
        year
        nickname
        licensePlate
        photoUrl
      }
      pagination {
        ...Pagination
      }
    }
  }
  ${Fragments.PaginationFragment}
`;

export const updateSpeedBuffer = gql(`
  mutation updateSpeedBuffer($id: ID!, $postedSpeedLimitBuffer: Float){
    updateVehicle(id: $id, vehicle: { postedSpeedLimitBuffer: $postedSpeedLimitBuffer }){
      id
      postedSpeedLimitBuffer
    }
  }
`);

export const getVehicleQuery = gql`
  query getVehicle($id: ID!) {
    vehicle(id: $id) {
      id
      make
      model
      year
      nickname
      serialNumber
      vin
      newVin
      photoUrl
      licensePlate
      tireIssue
      assetTemperature
      assetTemperatureTimestamp
      bodyType
      smartcarEnabled
      smartcarAccessToken
      smartcarRefreshToken
      smartcarUser
      smartcarPassword
      smartcarVehicleId
      tags{
        id
        name
      }
      tires {
        airLeakageRate
        axle
        faultCode
        id
        psi
        temperature
        tire
      }
      odometer {
        value
        calcLogic
      }
      fuelUsed
      deviceOdometer
      calculatedOdometer
      engineHours {
        value
        calcLogic
      }
      deviceEngineHours
      calculatedEngineHours
      deviceOdometerOffsetComment
      deviceOdometerOffset
      deviceEngineHoursOffset
      deviceEngineHoursOffsetComment
      idleTimeCalcLogic
      fuelConsumption
      engineType
      fuelType
      fuelLevel
      maintenanceThreshold
      idleTime
      dtcIssue
      dtcIssueCount
      batteryIssue
      drivingStatus
      drivingStatusTimestamp
      drivingStatusPlace {
        id
        address
      }
      status
      trackingObjectType {
        id
        name
      }
      trackingObjectUuid
      postedSpeedLimitBuffer
      thresholdSpeedLimit
      batteryVoltage
      engineTemperature
      idleThreshold
      engineTemperatureThreshold
      batteryVoltageThreshold
      fuelLevelThreshold
      location: drivingStatusLocation {
        address {
          fullAddress
          city
          street
          state
          houseNumber
          postalCode
        }
        speed
        latitude
        longitude
      }
      tripsStats {
        totalDuration
        totalIdleTime
        maxSpeed
      }
      currentDriver {
        id
        bleDriverId
        user {
          id
          firstName
          lastName
        }
      }
      devicesInfo {
        id
        mobileId
        type
        iccid
        activity {
          lastPing
          status
        }
        deviceModel {
          id
          make
          model
          type
          sensors {
            temperature
          }
        }
      }
      devices {
        mobileId
        id
        firmwareVersion
        firmwareUpdateRequired
        insideCamera
        iccid
        deviceModel {
          id
          make
          model
          type
          sensors {
            temperature
          }
        }
      }
      lastRealtimeTrip {
        id
        startTime
      }
      lastTrip (status: [IMPOSSIBLE, NORMAL, SHORT, UNUSUAL]) {
        ...ExtendedTrip
        idleTimeCalcLogic
      }
      assignedDrivers {
        id
        user {
          id
          firstName
          lastName
          credentials {
            phone
            unconfirmedPhone
          }
        }
      }
    }
  }
  ${Fragments.ExtendedTripFragments}
`;

export const getVehicleMarkerCarInfoVehcile = gql`
  query getVehicle($id: ID!) {
    vehicle(id: $id) {
      make
      model
      year
      vin
      drivingStatusTimestamp
      devicesInfo {
        deviceModel {
          model
        }
      }
      tags{
        id
        name
      }
      batteryVoltage
      location: drivingStatusLocation {
        address {
          houseNumber
          street
          city
          state
          postalCode
        }
      }
    }
  }
`;

export const getTotalVehicleEntriesQuery = gql`
  query getVehicles(
    $filter: FilterByVehicle,
    $pagination: PaginationParams
  ) {
    vehicles(
      filter: $filter
      pagination: $pagination
    ) {
      entities{
        id
      }
      pagination{
        totalEntries
      }
    }
  }
`;

export const updateVehicleQuery = gql(`
  mutation updateVehicle($id: ID!, $vehicle: UpdateVehicleParams, $deviceIds: [ID]) {
    updateVehicle(id: $id, vehicle: $vehicle, deviceIds: $deviceIds) {
      id
      vin
      make
      model
      year
      nickname
      serialNumber
      licensePlate
      photoUrl
      odometer {
        value
        calcLogic
      }
      engineHours {
        value
        calcLogic
      }
      calculatedEngineHoursInitialValue
      calculatedOdometerInitialValue
      engineHoursCalcLogic
      odometerCalcLogic
      fuelType
      engineType
      fuelConsumption
      maintenanceThreshold
      postedSpeedLimitBuffer
      thresholdSpeedLimit
      idleThreshold
      engineTemperatureThreshold
      batteryVoltageThreshold
      fuelLevelThreshold
      deviceOdometerOffsetComment
      deviceOdometerOffset
      deviceEngineHoursOffset
      deviceEngineHoursOffsetComment
    }
  }
`);

export const swapDevicesQuery = gql(`
  mutation swapDevices($firstDeviceId: ID!, $secondDeviceId: ID!) {
    swapDevices(firstDeviceId: $firstDeviceId, secondDeviceId: $secondDeviceId) {
      status
    }
  }
`);

export const getVehicleMakes = gql(`
  query vehicleMakes {
    vehicleMakes {
      id
      name
      models {
        id
        name
        years
      }
    }
  }
`);

export const getVehicleHealthLogs = gql`
  query getVehicleHealthLogs($id: ID!, $paginationActive: PaginationParams, $paginationPast: PaginationParams) {
    vehicle(id: $id) {
      id
      engineTemperatureThreshold
      batteryVoltageThreshold
      fuelLevelThreshold
      activeLogs: issuesLogs(active: true, pagination: $paginationActive) {
        entities {
          active
          description
          id
          timestamp
          type
          value
          thresholdValue
        }
        pagination {
          ...Pagination
        }
      }

      pastLogs: issuesLogs(active: false, pagination: $paginationPast) {
        entities {
          active
          description
          id
          timestamp
          type
          value
          thresholdValue
        }
        pagination {
          ...Pagination
        }
      }
    }
  }
  ${Fragments.PaginationFragment}
`;

export const getVehicleDTCLogs = gql`
  query getVehicleDTCLogs($id: ID!, $pagination: PaginationParams) {
    vehicle(id: $id) {
      id
      issuesLogs(pagination: $pagination, types: [DTC_ISSUE]) {
        entities {
          id
          active
          value
          timestamp
          description
          dtcInfo {
            code
            description
          }
        }
        pagination {
          ...Pagination
        }
      }
    }
  }
  ${Fragments.PaginationFragment}
`;

export const getVehicleStats = gql`
  query getVehicleStats($id: ID!, $countMonth: Int!, $perPage: Int) {
    vehicle(id: $id) {
      id
      issuesStats(groupBy: MONTH, count: $countMonth, types: [BATTERY_ISSUE, DTC_ISSUE, ENGINE_ISSUE]) {
        date
        dtcIssue
        engineIssue
        batteryIssue
      }
      vehicleScorings(countMonths: $countMonth) {
        date
        score
        tripsCount
        totalDistance
        totalDuration
        fuelUsed
        idleTime
        harshBrakingCount
        harshAccelerationCount
        harshTurnCount
        speedingCount
      }
      crashAlerts(countMonths: $countMonth, pagination: { perPage: $perPage }) {
        entities {
          id
          timestamp
          driver {
            id
            user {
              id
              firstName
              lastName
              photoUrl
            }
          }
        }
        pagination {
          ...Pagination
        }
      }
    }
  }
  ${Fragments.PaginationFragment}
`;

export const getFutureSM = gql(`
  query getFutureSM ($id: ID!) {
    vehicle (
      id: $id
    ) {
      id
      odometer {
        value
      }
      nextMaintenanceMileage
      serviceMaintenances {
        future {
          mileage
          actions {
            item
            serviceMaintenanceRuleId
          }
        }
      }
    }
  }
`);

export const getPastSM = gql`
  query vehicle($id: ID!) {
    vehicle(id: $id) {
      id
      serviceMaintenances {
        past {
          entities {
            id
            mileage
            completedMileage
            completedAt
            placeName
            items {
              id
              completed
              name
              serviceMaintenanceRuleId
            }
            vehicle {
              id
              odometer {
                value
              }
            }
          }
          pagination {
            ...Pagination
          }
        }
      }
    }
  }
  ${Fragments.PaginationFragment}
`;

export const decodeVin = gql(`
  mutation decodeVin($vin: String!) {
    decodeVin(vin: $vin) {
      make
      model
      year
    }
  }
`);

export const createVehicle = gql(`
  mutation createVehicle(
    $accountId: ID!,
    $deviceIds: [ID],
    $make: String,
    $model: String,
    $nickname: String,
    $vin: String,
    $serialNumber: String,
    $year: Int,
    $fuelType: VehicleFuelType,
    $engineType: String,
    $trackingObjectTypeId: ID!,
    $photoUrl: Base64Upload,
  ) {
    createVehicle(
    accountId: $accountId,
    deviceIds: $deviceIds,
    make: $make,
    model: $model,
    nickname: $nickname,
    vin: $vin,
    serialNumber: $serialNumber,
    year: $year,
    fuelType: $fuelType,
    engineType: $engineType,
    trackingObjectTypeId: $trackingObjectTypeId,
    photoUrl: $photoUrl
    ) {
      id
    }
  }
`);

export const activateVehicle = gql(`
  mutation activateVehicle($id: ID!) {
    activateVehicle(id: $id) {
      id
      status
    }
  }
`);

export const deactivateVehicle = gql(`
  mutation deactivateVehicle($id: ID!) {
    deactivateVehicle(id: $id) {
      id
      status
    }
  }
`);

export const getTrackingObjectTypes = gql`
  query getTrackingObjectTypes($pagination: PaginationParams, 
    $filter: FilterByTrackingObjectType, $searchValue: String) {
    trackingObjectTypes(pagination: $pagination, filter: $filter, searchValue: $searchValue) {
      entities {
        id
        account {
          id
          billingFirstName
        }
        name
      }
      pagination {
        ...Pagination
      }
    }
  }
  ${Fragments.PaginationFragment}
`;

export const getVehicleAccountStats = gql(`
  query getVehicleAccountStats (
    $filter: FilterByVehicle,
    $datetimerange: Datetimerange!
    $tripStatus: [TripStatus]
    ) {
    vehicleAccountStats (
      filter: $filter,
      datetimerange: $datetimerange
      tripStatus: $tripStatus
    ) {
      harshAccelerationCount
      harshBrakingCount
      harshTurnCount
      hour
      speedingDistance
      totalDistance
      tripCount
      idleTime
      driveTime
      diagnosticCount
      maintenanceCount
    }
  }
`);

export const getVehicleInactivity = gql(`
  query getVehicleInactivity ($filter: FilterByVehicle) {
    vehicleInactivity (filter: $filter) {
      day1
      day15
      day30
      day7
    }
  }
`);

export const acceptNewVin = gql(`
  mutation acceptNewVin($id: ID!) {
    acceptNewVin(id: $id) {
      status
    }
  }
`);

export const getСlusterVehicles = gql(`
  query clusterVehicles(
    $bottomRightPoint: InputLocation!, 
    $topLeftPoint: InputLocation!,
    $filter: FilterByVehicle,
    $search: VehicleSearch,
    $zoomLevel: Float!
    ){
    clusterVehicles(
      bottomRightPoint: $bottomRightPoint, 
      topLeftPoint: $topLeftPoint,
      filter: $filter,
      search: $search,
      zoomLevel: $zoomLevel
    ) {
      count
      location {
        latitude
        longitude
      }
      vehicle {
        id
        make
        model
        year
        nickname
        vin
        drivingStatus
        drivingStatusTimestamp
        photoUrl
        fuelType
        tagsCount
        engineType
        fuelConsumption
        fuelLevel
        fuelLevelThreshold
        trackingObjectUuid
        issuesLogCount {
          battery: batteryIssue
          dtc: dtcIssue
          engine: engineIssue
          tires: tireIssue
        }
        location: drivingStatusLocation {
          altitude
          hdop
          latitude
          longitude
          speed
          address {
            city
            street
            houseNumber
            state
            fullAddress
          }
        }
        assignedDrivers {
          id
          user {
            id
            firstName
            lastName
            credentials {
              phone
              unconfirmedPhone
            }
          }
        }
        currentDriver {
          id
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`);
export const accountVehiclesExport = gql(`
  query accountVehiclesExport($filter: FilterByVehicle, $searchValue: String) {
    accountVehiclesExport(filter: $filter, searchValue: $searchValue) {
      exportId
    }
  }
`);

export const getVehiclesNames = gql`
query getVehicles($pagination: PaginationParams, $filter: FilterByVehicle, 
  $search: VehicleSearch, $sortBy: VehiclesSortBy) {
  vehicles(pagination: $pagination, filter: $filter, search: $search, sortBy: $sortBy) {
    entities {
      id
      nickname
    }
    pagination {
      ...Pagination
    }
  }
}
${Fragments.PaginationFragment}
`;

export const getVehicleOdometerQuery = gql`
  query getVehicle($id: ID!) {
    vehicle(id: $id) {
     
      odometer {
        value
        calcLogic
      }
    }
  }
`;

export const getVehicleBodyType = gql`
 mutation getVehicleBodyType($vin: String!) {
  vehicleBodyType(vin: $vin){    
    type    
    bodyType  
    }
  }
`;

export const updateVehicleDriver = gql`
mutation updateDefinedVehicle($id: ID!, $vehicleId: ID!) { 
  updateDefinedVehicle(id: $id, vehicleId: $vehicleId)
  { 
    status
  }
}`;

export const getDriverIdByBleNumber = gql`
query getDriverIdByBleNumber($driveridNumber: String!){
  findByBleDriveridNumber(driveridNumber: $driveridNumber){
   id
   bleDriverId
   user {
        id
        firstName
        lastName
     }    
  }
}`;

export const getDriverById = gql(`
  query driver($id: ID!) {
    driver (id: $id) {    
      id
      user {
        id
        firstName
        lastName
      }
    }
  }
`);
