import { useContext } from 'react';
import { GeofenceContext } from 'context/GeofenceContext';
import {
  UPDATE,
  UPDATE_LIST,
  UPDATE_BOUNDARIES
} from 'context/GeofenceContext/constants';

const useGeofence = () => {
  const { state, dispatch } = useContext(GeofenceContext);

  const setGeofenceList = geofenceList => dispatch({ type: UPDATE_LIST, geofenceList });
  const setListFlag = updateFlag => dispatch({ type: UPDATE, updateFlag });
  const setBoundaries = boundaries => dispatch({ type: UPDATE_BOUNDARIES, boundaries });
  return {
    ...state,
    setGeofenceList,
    setListFlag,
    setBoundaries
  };
};

export default useGeofence;
